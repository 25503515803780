import React from 'react'

export const Website = () => {
  
  return (
    
    <div>

<div className="innerhome projects-shuffle main-gradiant position-relative d-flex align-items-center justify-content-center flex-column">
        <span className="in-shape-1"></span>
        <span className="in-shape-2"></span>
        <span className="in-shape-3"></span>
        <span className="in-shape-4"></span>
        <h1>Website Development</h1>
        <div className="inner-path">
            <a href="/" className="home">Home</a>
            <i className="fas fa-arrow-right"></i>
            <span>Website Development</span>
        </div>

    </div>
    <section className="about section-padding" id="about">
        <div className="container">
            <div className="about-field justify-content-between row">
                <div className="about-content col-12 col-lg-12">
                    <div className="text">
                        <h5 className="pb-2">Website Development Process of Sphinx Technology</h5>
                        <h2>We are well Experienced in  Technology</h2>
                    </div>
                    <p className="pb-2">

                    Sphinx Technology is a software development company that offers dynamic website development services. The company specializes in creating custom websites that are designed to meet the specific needs of clients. The services offered by Sphinx Technology for dynamic website development include:
                    </p>

                    <div className="text">
                             <h2>Features of Sphinx Technology's  Website development include:

</h2>
            </div>

        <ul className="form-control">
            
            <br />
            <br />
            
            <li>

            Concept Development: The company helps clients to define and refine their website ideas.

       
           </li>
<hr />

            <li>
     

            UI/UX Design: Sphinx Technology creates visually appealing and user-friendly designs for websites.

       
      </li>
            <hr />


            <li>
Website Development: The company develops dynamic websites using the latest technologies such as HTML, CSS, JavaScript, and PHP.
 </li>
<hr />
            <li>
Content Management Systems (CMS): Sphinx Technology integrates popular CMS platforms such as WordPress, Drupal, and Joomla into the websites.


            </li>
<hr />

<li>
Database Development: The company develops custom databases to store and manage website data.

       </li>
<hr />

<li>

Quality Assurance: Sphinx Technology ensures that the websites are thoroughly tested and free of bugs.
</li>

<hr/>

<li>
    

Deployment: The company deploys the websites to a web server or cloud hosting platform.

</li>

<hr/>

<li>
    
Maintenance and Support: Sphinx Technology provides ongoing maintenance and support services to keep the websites running smoothly.
</li>

            </ul>        


                </div>
        
            </div>
        </div>
    </section>

    </div>
  )
}
