import React from 'react'

const style={


backgroundColor:'green',


}


;

const a=2;
const b=7;

const res=a+b;



export const Thank = (props) => {
  return (
    <div>

<div className="innerhome main-gradiant position-relative d-flex align-items-center justify-content-center flex-column">
        <span className="in-shape-1"></span>
        <span className="in-shape-2"></span>
        <span className="in-shape-3"></span>
        <span className="in-shape-4"></span>
        <h1>Notification</h1>
       

    </div>

    <div className="jumbotron jumbotron-fluid  ">
  <div className="container"><br/><br/><br/><br/>
    <h1 className="display-4">Thank You</h1>
    <hr/>
    <p className="lead">You have successfully submitted the form.</p>

    <br/>
    <br/>
    <br/>
    <br/>
  </div>
</div>

    </div>
  )
}
