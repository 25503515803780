import React from 'react'

export const Webapp = () => {
  
  return (
    
    <div>

<div className="innerhome projects-shuffle main-gradiant position-relative d-flex align-items-center justify-content-center flex-column">
        <span className="in-shape-1"></span>
        <span className="in-shape-2"></span>
        <span className="in-shape-3"></span>
        <span className="in-shape-4"></span>
        <h1>Web Application Software</h1>
        <div className="inner-path">
            <a href="index-2.html" className="home">Home</a>
            <i className="fas fa-arrow-right"></i>
            <span>Web Application Software</span>
        </div>

    </div>
    <section className="about section-padding" id="about">
        <div className="container">
            <div className="about-field justify-content-between row">
                <div className="about-content col-12 col-lg-12">
                    <div className="text">
                        <h5 className="pb-2">Software Development Process of Sphinx Technology</h5>
                        <h2>We are well Experienced in  Technology</h2>
                    </div>
                    <p className="pb-2">


                    Sphinx Technology is a web application software development company that specializes in creating custom web applications for businesses of all sizes. Our team of experienced developers and designers are dedicated to creating high-quality, user-friendly web applications that are tailored to meet the unique needs of each client.


                    </p>

                    <div className="text">
                             <h2>Features of Sphinx Technology's web application development include:

</h2>
            </div>

        <ul className="form-control">
            
            <br />
            <br />
            
            <li>

            Custom design and development: We work closely with our clients to understand their specific needs and create a web application that is tailored to their unique requirements.
            </li>
<hr />

            <li>
            User-friendly interface: Our web applications are designed with a user-friendly interface that makes them easy to navigate and use.
            </li>
            <hr />


            <li>
            Responsive design: Our web applications are optimized for use on all devices, including desktop computers, laptops, tablets, and smartphones.
            </li>
<hr />
            <li>

            Secure and scalable: We use the latest security protocols to ensure that our web applications are secure and can easily scale to meet the needs of growing businesses.
            </li>
<hr />

<li>
Integration with third-party services: Our web applications can be integrated with a wide range of third-party services, such as social media, e-commerce platforms, and more.
</li>
<hr />

<li>
Ongoing support: We provide ongoing support and maintenance to ensure that our web applications continue to function smoothly and are updated to meet the latest standards.
</li>


            </ul>        


                </div>
        
            </div>
        </div>
    </section>

    </div>
  )
}
