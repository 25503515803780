import React from 'react'

export const Hospital = () => {
  
  return (
    
    <div>

<div className="innerhome projects-shuffle main-gradiant position-relative d-flex align-items-center justify-content-center flex-column">
        <span className="in-shape-1"></span>
        <span className="in-shape-2"></span>
        <span className="in-shape-3"></span>
        <span className="in-shape-4"></span>
        <h1>Hospital Automation System</h1>
        <div className="inner-path">
            <a href="index-2.html" className="home">Home</a>
            <i className="fas fa-arrow-right"></i>
            <span>Hospital Automation System</span>
        </div>

    </div>
    <section className="moreview bg-color-gray section-padding" id="moreview">
        <div className="container">
            <div className="over-field align-items-center row">
                <div className="image col-12 col-lg-6 pe-2 pe-lg-4 mb-4 mb-lg-0">
                    <img className="img-fluid w-100" src="hms'.jpg" alt="about image"/>
                </div>
                <div className="view-content col-lg-6">
                    <div className="text">
                        <h5 className="pb-2">Hospital Automation System</h5>
                        <h2>Software Based on Node JS, Livewire & LARAVEL framework</h2>
                    </div>
                    <p className="pb-2">Sphinx Technology Hospital Management System (HMS) is a software solution that streamlines and automates various administrative and clinical processes in a hospital.




</p>
                    <div className="merits pt-2">
<span>
Patient Management: Keeps track of patient information, appointments, medical history, and treatment plans.

</span>

<span>
    
Electronic Medical Records (EMR): Stores patient data electronically and securely, making it accessible to authorized personnel.

</span>
<span>

    
Appointments Management: Allows patients to book appointments online and helps the hospital keep track of schedules.

</span>

<span>
    
Billing and Invoicing: Automates billing and invoicing processes, reducing the risk of human error.

</span>

<span>

    
Inventory Management: Tracks the hospital's inventory of medical supplies and equipment.
</span>

<span>
    

Clinical Decision Support: Provides real-time information to support clinical decision making.
</span>

<span>
    

Reporting and Analytics: Generates various reports for decision-making purposes and performance analysis.

</span>

<span>
    
Security and Data Privacy: Ensures that patient data is kept confidential and secure.
</span>

                    </div>
                    <a class="blinking-button" href={`https://wa.me/+917896695062?text=I want to know Hospital Automation System`} target="_blank">
                    <font color="white">
                     
                     <i className="fas fa-location-arrow"></i>
                             
                     &nbsp;&nbsp;
                          Click Here to Enquiry</font>

</a>

                </div>
              
            </div>
        </div>
    </section>






    </div>
  )
}
