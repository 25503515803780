import React, { useState, useEffect  } from 'react';

export const Contact = () => {

  const [title, setTitle] = useState('');
  
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetch('https://dummyjson.com/products')
      .then(response => response.json())
      .then(data => {
        setTitle(data.title); // Extracting the title from the fetched data
        setProducts(data.products); // Setting the products data
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);


  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('http://isbarpeta.sphinx-india.tech/storeapi', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 201) {
        // Data stored successfully
        // Reset the form or show a success message
        setFormData({
          name: '',
          email: '',
          subject: '',
          message: '',
        });
        console.log('Data stored successfully');
      } else {
        // Handle errors
        console.error('Data could not be stored');
      }
    } catch (error) {
      console.error('An error occurred while sending the request:', error);
    }
  };

  return (
    <div>
    
<div className="innerhome main-gradiant position-relative d-flex align-items-center justify-content-center flex-column">
        <span className="in-shape-1"></span>
        <span className="in-shape-2"></span>
        <span className="in-shape-3"></span>
        <span className="in-shape-4"></span>
        <h1>Contact Us</h1>
        <div className="inner-path">
            <a href="/" className="home">Home</a>
            <i className="fas fa-arrow-right"></i>
            <span>Contact Us</span>
        </div>

    </div>
      <section className="contact section-padding">
        <div className="container">
          <div className="main-heading">
            <h5 className="pb-2">Contact</h5>
            <h2>Keep in touch with</h2>
          </div>
          <div className="cont-info pt-5 row">
            {/* Your contact information JSX goes here */}
          </div>
          <div className="contact-field">
            <div className="map">
              {/* Your map iframe goes here */}
            </div>
            <div className="contact-form-box">
              <h3>Send Us Messages</h3>
              <form className="form-contact" onSubmit={handleSubmit}>
                <div className="flex-form d-flex justify-content-between flex-wrap">
                  <div className="name">
                    <input
                      className="w-100"
                      type="text"
                      name="name"
                      required
                      placeholder="Name"
                      value={formData.name}
                      onChange={(e) =>
                        setFormData({ ...formData, name: e.target.value })
                      }
                    />
                  </div>
                  <div className="email">
                    <input
                      className="w-100"
                      type="email"
                      name="email"
                      required
                      placeholder="Email"
                      value={formData.email}
                      onChange={(e) =>
                        setFormData({ ...formData, email: e.target.value })
                      }
                    />
                  </div>
                  <div className="name">
                    <input
                      className="w-100"
                      type="text"
                      name="subject"
                      required
                      placeholder="Subject"
                      value={formData.subject}
                      onChange={(e) =>
                        setFormData({ ...formData, subject: e.target.value })
                      }
                    />
                  </div>
                  <div className="message">
                    <textarea
                      className="w-100"
                      name="message"
                      required
                      placeholder="Message"
                      value={formData.message}
                      onChange={(e) =>
                        setFormData({ ...formData, message: e.target.value })
                      }
                    ></textarea>
                  </div>
                </div>
                <button className="main-btn-2" type="submit">
                  Send Message <i className="far fa-paper-plane"></i>
                </button>
                <p className="respond-message"></p>
              </form>
            </div>
          </div>
        </div>
      </section>




    </div>



  );
};
