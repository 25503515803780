import React from 'react'

export const College = () => {
  
  return (
    
    <div>

<div className="innerhome projects-shuffle main-gradiant position-relative d-flex align-items-center justify-content-center flex-column">
        <span className="in-shape-1"></span>
        <span className="in-shape-2"></span>
        <span className="in-shape-3"></span>
        <span className="in-shape-4"></span>
        <h1>College Automation System</h1>
        <div className="inner-path">
            <a href="index-2.html" className="home">Home</a>
            <i className="fas fa-arrow-right"></i>
            <span>Seo Services</span>
        </div>

    </div>
    <section className="moreview bg-color-gray section-padding" id="moreview">
        <div className="container">
            <div className="over-field align-items-center row">
                <div className="image col-12 col-lg-6 pe-2 pe-lg-4 mb-4 mb-lg-0">
                    <img className="img-fluid w-100" src="college/college.jpg" alt="about image"/>
                </div>
                <div className="view-content col-lg-6">
                    <div className="text">
                        <h5 className="pb-2">College Automation (GU, DU and UGC affiliation college format)</h5>
                        <h2>Software Based on React JS, Livewire & LARAVEL framework</h2>
                    </div>
                    <p className="pb-2">Sphinx Technology's College Automation System is designed to improve efficiency, increase productivity, and provide real-time insights into college operations. The system is user-friendly, customizable, and can be integrated with other systems and software to ensure seamless and seamless operation.




</p>
                    <div className="merits pt-2">
                        <span>Admission Portal</span>
                        <span>College Notesheet</span>
                        <span>College Cashbook Management</span>
                        <span>Alumni Portal</span>
                        <span>Faculty Portal</span>
                        <span>Guardian Portal</span>
                        <span>Digital Class Schedule</span>
                        <span>Student Online Exam</span>
                        <span>
                            Digital Library & E-book
                        </span>

                        <span>
                            Multi Role Super Admin
                        </span>
                        <span>Website Management(CMS)</span>
                        <span>Exam & Admission Fees Collection(Payment Gateway)</span>
                    </div>
                    <a class="blinking-button" href={`https://wa.me/+917896695062?text=I want to know College Automation System`} target="_blank">

                        <font color="white">
                     
                        <i className="fas fa-location-arrow"></i>
                                
                        &nbsp;&nbsp;
                             Click Here to Enquiry</font>
 
</a>
                </div>
               

            </div>
        </div>
    </section>



    <section className="services section-padding">
        <div className="container">
            <div className="main-heading">
                <h2>College Automation System  Features in Depth</h2>
            </div>
            <div className="serv-field mt-5 row">
                <div className="serv-box mb-12 col-12 col-md-12 col-lg-12">
                    <div className="serv-cont p-4">
                        <img className="mt-3 mb-2" src="images/services/services1.png" alt="services image"/>
                        <h3 className="my-3">Student Portal</h3>



                        <p>

                        Login/Registration: secure, personalized access to student accounts.
<hr />
Dashboard: an overview of student performance, course progress, and academic history.
<hr />
Course Management: access to online courses, syllabus, assignments, and assessments.
<hr />
Grading System: tracking of grades, assignments, and exam results.
<hr />
Calendar: view upcoming assignments, exams, and events.
<hr />
Notifications: timely updates on course activities, announcements, and deadlines.
<hr />
Discussion Forums: communication with peers and instructors for course-related discussions.
<hr />
File Sharing: secure storage and sharing of course-related files and resources.
<hr />
Tutoring Services: virtual tutoring and academic support.
<hr />
Personalized Learning: individualized learning plans, progress tracking, and performance analysis.
<hr />
                        </p>
                              </div>


                              
                </div>

            </div>
        </div>
    </section>





    </div>
  )
}
