import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const Pdetails = () => {
  const { productId } = useParams();
  const [productDetails, setProductDetails] = useState(null);

  useEffect(() => {
    // Fetch product details using the productId from the URL parameter
    fetch(`https://dummyjson.com/products/${productId}`)
      .then(response => response.json())
      .then(data => {
       
        // Set the fetched product details in state
        setProductDetails(data);
   
    })
      .catch(error => {
        console.error('Error fetching product details:', error);
      });
  }, [productId]); // Dependency on productId to re-fetch data when it changes

  return (
    <div>


<div className="innerhome projects-shuffle main-gradiant position-relative d-flex align-items-center justify-content-center flex-column">
        <span className="in-shape-1"></span>
        <span className="in-shape-2"></span>
        <span className="in-shape-3"></span>
        <span className="in-shape-4"></span>
        <h1>About Us</h1>
        <div className="inner-path">
            <a href="index-2.html" className="home">Home</a>
            <i className="fas fa-arrow-right"></i>
            <span>About</span>
        </div>

    </div>

<section className="projects section-padding">
        <div className="container">
            <div className="main-heading">
                <h5 className="pb-2">Product Details</h5>
                <h2>Our Latest Gallery</h2>
            </div>
            <div className="prog-filter mt-4">

            {productDetails ? ( // Render product details if available
        <div>
          <h2>{productDetails.name}</h2>
          <img src={productDetails.thumbnail} alt={productDetails.name} />
          <p>{productDetails.description}</p>
          {/* Render other details of the product */}
        </div>
      ) : (
        <p>Loading...</p> // Show loading message while data is being fetched
      )}
               
            </div>

            </div>
            </section>



      
















    </div>
  );
};

export default Pdetails;
