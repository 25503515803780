import React from 'react'
import { Link } from 'react-router-dom'

export const Footer = () => {
  return (
    <div>

<footer className="pt-5 bg-dark">
        <div className="container pb-5">
            <div className="footer-field row">
                <div className="foot-about pb-2 pb-lg-0 mb-3 mb-lg-0 col-12 col-md-6 col-lg-4">
                    <h4>
                        <i className="fas fa-angle-double-right"></i>
                        Why Us?
                    </h4>
                    <p className="pb-4">Sphinx Technology also offers ongoing support and maintenance to ensure that its customers continue to receive the highest level of performance from their software. Whether you need assistance with a technical issue, or have a question about a feature, Sphinx Technology is there to help
                    </p>

                    
                    <div className="foot-social">

                        <ul className="d-flex ps-0">
                            <li className="px-2"><a to="#"><i className="fab fa-facebook-f"></i></a></li>
                            <li className="px-2"><a to="#"><i className="fab fa-twitter"></i></a></li>
                            <li className="px-2"><a to="#"><i className="fab fa-linkedin-in"></i></a></li>
                            <li className="px-2"><a to="#"><i className="fab fa-youtube"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div className="foot-contact pb-2 pb-lg-0 mb-3 mb-lg-0 col-12 col-md-6 col-lg-4">
                    <h4>
                        <i className="fas fa-angle-double-right"></i>
                        Contact Us
                    </h4>
                    <div className="footCont-link d-flex">
                        <i className="fas fa-map-marker-alt"></i>
                        <address className="mb-0">H.No 77K,Nilachalpur Bezbaruah Nagar,Ghy-781011, Assam</address>
                    </div>
                    <div className="footCont-link d-flex">
                        <i className="far fa-paper-plane"></i>
                        <a to="#">support@sphinxtech.in.net</a>
                    </div>
                    <div className="footCont-link d-flex">
                        <i className="fas fa-phone"></i>
                        <a to="tel:+91-7002583434">+91-7002583434</a>
                    </div>
                   
                    <div className="footCont-link d-flex">
                        <i className="fas fa-phone"></i>
                        <a to="tel:+91-7002361174">+91-7002361174</a>
                    </div>
                   

                   
                    <div className="subscribtion mt-3">
                        <form action="#">
                            <div className="sub-box">
                                <input type="email" placeholder="Email" required/>
                                <button type="submit">
                                    Send
                                    <i className="fas fa-location-arrow"></i>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="foot-services pb-2 pb-lg-0 mb-6 mb-lg-0 col-12 col-md-6 col-lg-2">
                    <h4>
                        <i className="fas fa-angle-double-right"></i>
                       Find Us
                    </h4>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d25927.26638233374!2d91.67684974809052!3d26.15369780684651!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x375a5b3d391a9f77%3A0x83155c6f5628eab7!2sMaligaon%2C%20Guwahati%2C%20Assam!5e1!3m2!1sen!2sin!4v1709876183630!5m2!1sen!2sin" style={{height:'300px',width:'350px'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
               
            </div>
        </div>
        <div className="container">
            <div className="copy-right d-flex flex-column flex-md-row justify-content-between align-items-center py-3">
                <p className="mb-3 mb-md-0 text-center">&copy; 2013-2023 All Right Reserved </p>
                <div className="copyRight-links position-relative">
                    <a className="mx-2" to="#">Privacy Policy</a>
                    <span></span>
                    <a className="mx-2" to="#"> Terms &amp; Conditions</a>
                </div>
            </div>
        </div>
    </footer>


    </div>
  )
}
