import React from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
  
export const Navbar = (isLoggedIn, username) => {
  return (
    <div>



    <header className="shadow-sm">
        <nav className="navbar-desktop d-none d-lg-block w-100">
            <div className="container d-flex justify-content-between align-items-center position-relative">
                <Link className="navbar-brand p-0" to="/">
                    <img src="/lol.png" height="100" width="200"/>
                </Link>
                <div className="ul-cont justify-content-center">
                    <ul className="navbar-nav d-flex flex-row justify-content-start mb-2 mb-lg-0">
                

                        <Link className="nav-link" to="/">Home <span className="sr-only">(current)</span></Link>
                        <li className="nav-item">
        <Link className="nav-link" to="/about">About Us</Link>
      </li>

                         <li className="nav-item drop-cont">
                            <Link className="nav-link" to="#" role="button">
                                Services
                                &nbsp;  <i className="fas fa-chevron-down"></i>
                            </Link>
                            <ul className="dropdown-items ps-0">
                                <li><Link className="nav-item" to="/web-application-software">Web Application Software</Link></li>
                                <li><Link className="nav-item" to="/mobile-application-development">Mobile Application</Link></li>
                                <li><Link className="nav-item" to="/website-development">Website Development </Link></li>
                             
                                <li><Link className="nav-item" to="/seo-services"> SEO Services</Link></li>
                                 <li><Link className="nav-item" to="/ecommerce-solution">E-Commerce Solution</Link></li>
                                  
                      
                            </ul>
                        </li>
                        <li className="nav-item drop-cont">
                            <Link className="nav-link" to="#" role="button">
                               Our  Product
                               &nbsp; <i className="fas fa-chevron-down"></i>
                            </Link>
                            <ul className="dropdown-items ps-0">
                              <li><Link className="nav-item" to="/college-automation-system">College Automation</Link></li>
                              <li><Link className="nav-item" to="/hospital-management-system">Hospital Management</Link></li>
                           
                              <li><Link className="nav-item" to="/computer-institute-software">Computer Inst. Software</Link></li>
                
                            </ul>
                        </li>


                  
                        <li className="nav-item">
        <Link className="nav-link" to="/team">Member</Link>
      </li>
                


                        <li className="nav-item">
        <Link className="nav-link" to="/gallery">Our Projects</Link>
      </li>
                     
      <li className="nav-item mx-1">
                            <Link className="nav-link" to="carrier">
                                
                               
                               Build Career</Link>
                        </li>
                    
                     
                        <li className="nav-item mx-1">
                            <Link className="nav-link" to="contact">Contact</Link>
                        </li>
                    
                    </ul>
                </div>
                <div className="header-quote d-none d-lg-block" >
                <Link className="unique-link" to="/login"> <i className="fa fa-user"></i> Client Login</Link>
        

          


                </div><div >
                <Link to="/create-account" className="btn btn-success"><i class="fa fa-align-right" ></i>  &nbsp;
 Client Registration</Link> 

          


                </div>
            </div>
        </nav>
        <nav className="navbar-responsive d-block d-lg-none w-100" style={{  background: 'linear-gradient(80deg,yellow, red, yelllow)'}}>
            <div className="container d-flex justify-content-between align-items-center position-relative">
                <Link className="navbar-brand p-0" to="/">
                <img src="/lol.png" height="60" width="120"/>  </Link>
                <div className="ul-cont justify-content-center ms-auto">
                















                    <ul className="navbar-nav mb-2 mb-lg-0">
                

                <Link className="nav-link" to="/">Home <span className="sr-only">(current)</span></Link>
                <li className="nav-item">
<Link className="nav-link" to="/about">About Us</Link>
</li>

                 <li className="nav-item drop-cont">
                    <Link className="nav-link" to="#" role="button">
                        Services
                        &nbsp;  <i className="fas fa-chevron-down"></i>
                    </Link>
                    <ul className="dropdown-items ps-0">
                        <li><Link className="nav-item" to="/web-application-software">Web Application Software</Link></li>
                        <li><Link className="nav-item" to="/mobile-application-development">Mobile Application</Link></li>
                        <li><Link className="nav-item" to="/website-development">Website Development </Link></li>
                     
                        <li><Link className="nav-item" to="/seo-services"> SEO Services</Link></li>
                         <li><Link className="nav-item" to="/ecommerce-solution">E-Commerce Solution</Link></li>
                          
              
                    </ul>
                </li>
                <li className="nav-item drop-cont">
                    <Link className="nav-link" to="#" role="button">
                       Our  Product
                       &nbsp; <i className="fas fa-chevron-down"></i>
                    </Link>
                    <ul className="dropdown-items ps-0">
                      <li><Link className="nav-item" to="/college-automation-system">College Automation</Link></li>
                      <li><Link className="nav-item" to="/hospital-management-system">Hospital Management</Link></li>
                   
                      <li><Link className="nav-item" to="/computer-institute-software">Computer Inst. Software</Link></li>
        
                    </ul>
                </li>


          
                <li className="nav-item">
                <Link className="nav-link" to="/team">Team Member</Link>
     </li>
        


                <li className="nav-item">
<Link className="nav-link" to="/gallery">Our Projects</Link>
</li>
             
<li className="nav-item mx-1">
                    <Link className="nav-link" to="carrier">
                        
                       
                        Career With Us</Link>
                </li>
            
             
                <li className="nav-item mx-1">
                    <Link className="nav-link" to="contact">Contact</Link>
                </li>
            
            </ul>
   


                </div>


                
                <button className="menu-toggle-button d-block d-lg-none" type="button">
                    <span className="header-nav-icon"></span>
                    <span className="header-nav-icon"></span>
                    <span className="header-nav-icon"></span>
                </button>
            </div>
        </nav>
    </header>












    </div>
  )
}
