import React from 'react'

const style={


backgroundColor:'green',


}


;

const a=2;
const b=7;

const res=a+b;



export const Gallery = (props) => {
  return (
    <div>

<div className="innerhome main-gradiant position-relative d-flex align-items-center justify-content-center flex-column">
        <span className="in-shape-1"></span>
        <span className="in-shape-2"></span>
        <span className="in-shape-3"></span>
        <span className="in-shape-4"></span>
        <h1>Projects</h1>
        <div className="inner-path">
            <a href="/" className="home">Home</a>
            <i className="fas fa-arrow-right"></i>
            <span>Latest Project Listing</span>
        </div>

    </div>

    <section className="projects section-padding">
        <div className="container">
         
            <div className="prog-filter mt-4">
               
            </div>
            <div className="projects-field projects-shuffle row">
                <div className="mix software prog-box p-3 col-12 col-md-6 col-lg-4" data-order="1">
                    <div className="d-block prog-cont">
                        <div className="image">
                            <img className="img-fluid w-100" src="bc.png" alt="project image"/>
                            <hr/>
                        </div>
                        
                        <div className="prog-desc">
                            <h4 className="project-name">Barbhag College</h4>
                            <span className="project-type">Website & College Automation Software</span>
                            <div className="prog-links pt-3">
                                <a className="mx-2 sec-btn" data-fancybox="gallery" data-caption="Software : Project Title" href="images/projects/project1.jpg"><i className="fas fa-search"></i></a>
                                <a className="mx-2 sec-btn" href="project-details.html"><i className="fas fa-link"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mix branding prog-box p-3 col-12 col-md-6 col-lg-4" data-order="2">
                    <div className="d-block prog-cont">
                        <div className="image">
                            <img className="img-fluid w-100" src="nk.png" alt="project image"/>
                        </div>
                        <div className="prog-desc">
                            <h4 className="project-name">North Kamrup College</h4>
                            <span className="project-type">Website & College Automation Software</span>
                              <div className="prog-links pt-3">
                                <a className="mx-2 sec-btn" data-fancybox="gallery" data-caption="Branding : Project Title" href="images/projects/project2.jpg"><i className="fas fa-search"></i></a>
                                <a className="mx-2 sec-btn" href="project-details.html"><i className="fas fa-link"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
           
            </div>
        </div>
    </section>


    </div>
  )
}
