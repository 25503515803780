import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';

export const Login = ({ history }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleLogin = async (e) => {
        e.preventDefault();
        if (!username.trim() || !password.trim()) {
            setError('Please fill in all fields.');
            return;
        }
        try {
            const response = await axios.post('https://dummyjson.com/auth/login', {
                username,
                password
            });

            if (response.status === 200) {
                setError('');
                history.push('/dashboard');
            } else {
                setError('Invalid username or password.');
            }
        } catch (error) {
            console.error('Error:', error);
            setError('Username or Password not matched.');
        }
    };

    return (
        <div>
    
<div className="innerhome main-gradiant position-relative d-flex align-items-center justify-content-center flex-column">
        <span className="in-shape-1"></span>
        <span className="in-shape-2"></span>
        <span className="in-shape-3"></span>
        <span className="in-shape-4"></span>
        <h1>Client Login</h1>
        <div className="inner-path">
            <a href="/" className="home">Home</a>
            <i className="fas fa-arrow-right"></i>
            <span>Client Login</span>
        </div>

    </div>

           
<div className='container'>


    <div className='row'>

<div className='col-md-6'>

<img src="/log.jpg" className='img-fluid'/>
</div>





<div className='col-md-6 shadow-lg'>

<form onSubmit={handleLogin}>
<br/>
<br/>
                <div>

                    
                    <label>Username:</label>
                    <input
                        type="text" className='form-control'
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>
                <br/>
           
                <div>
                    <label>Password:</label>
                    <input
                        type="password" className='form-control'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <br/>
               
                <button type="submit" className='btn btn-danger'>Login</button>
                <br/>
                
           
            </form>
            {error && <p style={{ color: 'red' }}>{error}</p>}

            <br/>
            <br/>
            <br/>
           
</div>





    </div>
</div>
           
           
        </div>
    );
};

export default withRouter(Login);
