import React, { useState } from 'react';
import { useHistory } from 'react-router-dom'; // Import useHistory from React Router


export const Carrier = (props) => {const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
});
const [successMessage, setSuccessMessage] = useState('');
const history = useHistory(); // Get history object from React Router

const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
};

const handleSubmit = async (e) => {
    e.preventDefault();


    if (!formData.name || !formData.email || !formData.message) {
        alert('Please fill in all fields');
        return;
    }
    try {
        const response = await fetch('http://edisonhospital.in/api/contact', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        });
        if (response.ok) {
            setSuccessMessage('Contact form submitted successfully');
            setFormData({
                name: '',
                email: '',
                message: ''
            });
            // Redirect to another page
            history.push('/thank-you'); // Change '/thank-you' to the desired route
        } else {
            // Handle error (e.g., show an error message)
            console.error('Error submitting contact form');
        }
    } catch (error) {
        console.error('Error submitting contact form:', error);
    }
};
return (
    <div>
        

<div className="innerhome main-gradiant position-relative d-flex align-items-center justify-content-center flex-column">
        <span className="in-shape-1"></span>
        <span className="in-shape-2"></span>
        <span className="in-shape-3"></span>
        <span className="in-shape-4"></span>
        <h1>Carrier</h1>
        <div className="inner-path">
            <a href="/" className="home">Home</a>
            <i className="fas fa-arrow-right"></i>
            <span>Build Your Carrier with us</span>
        </div>

    </div>

<div className='container'>


<div className='row'>

<div className='col-md-6'>

<img src="v.png" className='img-flluid'/>
</div>

<div className='col-md-6'>
{successMessage && <div className="success-popup">{successMessage}</div>}
        <form onSubmit={handleSubmit}>        <br/>        <br/>
   <input
                type="text"
                name="name" className='form-control'
                value={formData.name}
                onChange={handleChange}
                placeholder="Your Name"
            />
            <br/>
            <input
                type="email"
                name="email" className='form-control'
                value={formData.email}
                onChange={handleChange}
                placeholder="Email"
            />
            <br/>
            <textarea
                name="message"className='form-control'
                value={formData.message}
                onChange={handleChange}
                placeholder="Message"
            />
                    <br/>        <br/>
            <button className='btn btn-success' type="submit"> <i className='fa fa-arrow-right'></i> Submit</button>
        </form>

        <br/>        <br/>

</div>



</div>



</div>


        </div>
    );
  }








