
import logo from './logo.svg';

import { Helmet } from 'react-helmet';

import './css/style.css';
import { Navbar } from './Component/Navbar';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  withRouter
} from "react-router-dom";

import { Hospital } from './Component/Hospital';
import { Footer } from './Component/Footer';
import { Thank } from './Component/Thank';
import { Team } from './Component/Team';
import { Home } from './Component/Home';
import { Register } from './Component/Register';
import Pdetails from './Component/Pdetails';

import { About } from './Component/About';


import { Carrier } from './Component/Carrier';


import { Login } from './Component/Login';

import { Contact } from './Component/Contact';

import {Gallery } from './Component/Gallery';
import {Webapp } from './Component/Webapp';
import {College } from './Component/College';

import {Website } from './Component/Website';

import {Seo} from './Component/Seo';


import {Ecommerce} from './Component/Ecommerce';
import {Mobileapp } from './Component/Mobileapp';
import {Computer } from './Component/Computer';

function App() {
  return (
<>

    <Router>    
    <Navbar  title="Sphinx Technology"/>
       {/* Define meta tags for each route */}
    

    <Switch>

    <Route exact path="/" component={Home} />
      
    <Route exact path="/about" component={About} />
    <Route exact path="/seo-services" component={Seo} />
    <Route exact path="/ecommerce-solution" component={Ecommerce} />
    <Route exact path="/college-automation-system" component={College} />
   
    <Route exact path="/hospital-management-system" component={Hospital} />
    <Route exact path="/carrier" component={Carrier} />
    <Route exact path="/team" component={Team} />
   
   
        
    <Route exact path="/computer-institute-software" component={Computer} />
    <Route path="/pdetails/:productId" component={Pdetails} />
   
   
    <Route exact path="/create-account" component={Register} />
   
        
    <Route exact path="/thank-you" component={Thank} />
    <Route exact path="/login" component={Login} />
    
    <Route exact path="/website-development" component={Website} />
    
    <Route exact path="/mobile-application-development" component={Mobileapp} />
    <Route exact path="/web-application-software" component={Webapp} />
    <Route exact path="/gallery" >

<Gallery title="Sphinx Technology"/>
      </Route>
  

          <Route exact path="/contact" component={Contact} />
            
          
        </Switch>
<Footer/>



  



    </Router>
    </>

  );
}

export default App;
