import React from 'react'
import { Helmet } from 'react-helmet';


export const Team = () => {
  
  return (
    
    <div>

<Helmet>
        <title>Team Member-Sphinx-Tech</title>
        <meta name="description" content="Our Team" />
        {/* Add any other specific meta tags for the About page */}
      </Helmet>


<div className="innerhome projects-shuffle main-gradiant position-relative d-flex align-items-center justify-content-center flex-column">
        <span className="in-shape-1"></span>
        <span className="in-shape-2"></span>
        <span className="in-shape-3"></span>
        <span className="in-shape-4"></span>
        <h1>Team Member</h1>
        <div className="inner-path">
            <a href="index-2.html" className="home">Home</a>
            <i className="fas fa-arrow-right"></i>
            <span>Team Member</span>
        </div>

    </div>
    <section className="about section-padding" id="about">
        <div className="container">
            <div className="about-field justify-content-between row">
            

<div className='col-md-4 shadow-lg'>

<div className="card  mb-3  shadow-lg" >
  <div className="card-body">
    <center>
<img src="/team/my.jpg" className="img-fluid" style={{height:'350px', width:'300px'}} />
    <h4 className="card-title">RAJIB DAS</h4>
    <hr/>
    <p className="card-text">DEVELOPER</p>

    </center>
    </div>
</div>

</div>




<div className='col-md-4 shadow-lg'>

<div className="card  mb-3  shadow-lg" >
  <div className="card-body">
    <center>
<img src="/team/c.jpg" className="img-fluid" style={{height:'350px', width:'300px'}} />
    <h4 className="card-title">CHANDAN ROY</h4>
    <hr/>
    <p className="card-text">BUSINESS DEVELOPER</p>

    </center>
    </div>
</div>

</div>


<div className='col-md-4 shadow-lg'>

<div className="card  mb-3  shadow-lg" >
  <div className="card-body">
    <center>
<img src="/team/l.jpg" className="img-fluid" style={{height:'350px', width:'300px'}} />
    <h4 className="card-title">AYUSHMAN DAS</h4>
    <hr/>
    <p className="card-text">JR. DEVELOPER</p>

    </center>
    </div>
</div>

</div>

<div className='col-md-4 shadow-lg'>

<div className="card  mb-3  shadow-lg" >
  <div className="card-body">
    <center>
<img src="/team/k.jpeg" className="img-fluid" style={{height:'350px', width:'300px'}} />
    <h4 className="card-title">HIRAKJYOTI DAS</h4>
    <hr/>
    <p className="card-text">JR. DEVELOPER</p>

    </center>
    </div>
</div>

</div>

<div className='col-md-4 shadow-lg'>

<div className="card  mb-3  shadow-lg" >
  <div className="card-body">
    <center>
<img src="/team/j.jpeg" className="img-fluid" style={{height:'350px', width:'300px'}} />
    <h4 className="card-title"> JYOTISMAN HAZARIKA</h4>
    <hr/>
    <p className="card-text">JR. DEVELOPER</p>

    </center>
    </div>
</div>

</div>




            </div>
        </div>
    </section>

    </div>
  )
}
