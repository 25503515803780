import React from 'react'

export const Mobileapp = () => {
  
  return (
    
    <div>

<div className="innerhome projects-shuffle main-gradiant position-relative d-flex align-items-center justify-content-center flex-column">
        <span className="in-shape-1"></span>
        <span className="in-shape-2"></span>
        <span className="in-shape-3"></span>
        <span className="in-shape-4"></span>
        <h1>Mobile Application</h1>
        <div className="inner-path">
            <a href="index-2.html" className="home">Home</a>
            <i className="fas fa-arrow-right"></i>
            <span>Android & IOS Application Development</span>
        </div>

    </div>
    <section className="about section-padding" id="about">
        <div className="container">
            <div className="about-field justify-content-between row">
                <div className="about-content col-12 col-lg-12">
                    <div className="text">
                        <h5 className="pb-2">Software Development Process of Sphinx Technology</h5>
                        <h2>We are well Experienced in  Technology</h2>
                    </div>
                    <p className="pb-2">


                    Sphinx Technology is a software development company that offers mobile app development services. The company specializes in creating custom mobile apps for iOS and Android platforms. The services offered by Sphinx Technology for mobile app development include:

                    </p>

                    <div className="text">
                             <h2>Features of Sphinx Technology's Mobile application development include:

</h2>
            </div>

        <ul className="form-control">
            
            <br />
            <br />
            
            <li>

       
Concept Development: The company helps clients to define and refine their app ideas.
       
       
           </li>
<hr />

            <li>
     

UI/UX Design: Sphinx Technology creates visually appealing and user-friendly designs for mobile apps.
      </li>
            <hr />


            <li>
     
App Development: The company develops native and cross-platform apps using the latest technologies. </li>
<hr />
            <li>


            Quality Assurance: Sphinx Technology ensures that the apps are thoroughly tested and free of bugs.

            </li>
<hr />

<li>

Deployment: The company deploys the apps to the App Store or Google Play Store.

       </li>
<hr />

<li>
Maintenance and Support: Sphinx Technology provides ongoing maintenance and support services to keep the apps running smoothly.
eet the specific needs of clients.
</li>

<hr/>

<li>
    
The company's team of experienced mobile app developers has a proven track record of delivering high-quality mobile apps that m
</li>



            </ul>        


                </div>
        
            </div>
        </div>
    </section>

    </div>
  )
}
