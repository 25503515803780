import React from 'react'

export const Ecommerce = () => {
  
  return (
    
    <div>

<div className="innerhome projects-shuffle main-gradiant position-relative d-flex align-items-center justify-content-center flex-column">
        <span className="in-shape-1"></span>
        <span className="in-shape-2"></span>
        <span className="in-shape-3"></span>
        <span className="in-shape-4"></span>
        <h1>E-Commerce Solution</h1>
        <div className="inner-path">
            <a href="index-2.html" className="home">Home</a>
            <i className="fas fa-arrow-right"></i>
            <span>E-Commerce Solution</span>
        </div>

    </div>
    <section className="about section-padding" id="about">
        <div className="container">
            <div className="about-field justify-content-between row">
                <div className="about-content col-12 col-lg-12">
                    <div className="text">
                        <h5 className="pb-2">E-Commerce Solution Process of Sphinx Technology</h5>
                        <h2>We are well Experienced in  Technology</h2>
                    </div>
              

                    <div className="text">
                             <h2>Features of Sphinx Technology's E-Commerce Solution:

</h2>
            </div>

        <ul className="form-control">
            
            <br />
            <br />
            
            <li>

            Shopify: A cloud-based, all-in-one platform that offers a range of e-commerce tools and features. </li>

            <li>
           
Magento: An open-source platform that provides a range of e-commerce features, including product management, payment processing, and customer management.
</li>
            <hr />


            <li>
            WooCommerce: A WordPress plugin that provides a simple and easy-to-use e-commerce platform for small and medium-sized businesses.
     </li>
<hr />
            <li>

     
BigCommerce: A cloud-based platform that offers a range of e-commerce tools and features, including product management, payment processing, and shipping integrations.
    </li>
<hr />

<li>OpenCart: An open-source platform that provides a range of e-commerce features, including product management, payment processing, and customer management.        </li>
<hr />



            </ul>        


                </div>
        
            </div>
        </div>
    </section>

    </div>
  )
}
